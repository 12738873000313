import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./Styles.module.css";
import { FaPlusCircle, FaFileAlt, FaUsers, FaUser, FaArrowLeft, FaBars, FaTimes, FaAlignLeft, FaPoll, FaArchive, FaCheckCircle, FaEye,FaEyeSlash, FaCheck , FaSignOutAlt, FaChalkboardTeacher, FaCogs, FaHome, FaVoteYea, FaScroll, FaTv, FaPlay, FaPause, FaStop, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalVotacao from "../components/Modals/ModalVotacao";
import ModalPresenca from "../components/Modals/ModalPresenca";


const PainelControle = ({ ordemDoDia, pautaId, minuta, titulo, tipo, atualizarDados }) => {
  const navigate = useNavigate();
  const [sessao, setSessao] = useState(null);
  const [sessaoOrdem, setSessaoOrdem] = useState([]);
  const [sessaoExpediente, setSessaoExpediente] = useState([]);
  const [itensExibidos, setItensExibidos] = useState([]);
  const [sessaoTribuna, setSessaoTribuna] = useState([]);
  const [pautas, setPautas] = useState([]);
  const [pautasDisponiveis, setPautasDisponiveis] = useState([]);
  const [tempoRestante, setTempoRestante] = useState(null);
  const [intervaloTribuna, setIntervaloTribuna] = useState(null);  
  const [tipoVotacao, setTipoVotacao] = useState(""); // Estado para armazenar o tipo de votação
  const [exibirExpediente, setExibirExpediente] = useState(true);
  const [exibirOrdem, setExibirOrdem] = useState(true);
  const [exibirTribuna, setExibirTribuna] = useState(true);
  const [resultado, setResultado] = useState(null);
  const [mostrarModalVotacao, setMostrarModalVotacao] = useState(false);
  const [sessaoAtual, setSessaoAtual] = useState(null);
  const [modalPresencaOpen, setModalPresencaOpen] = useState(false);
  const cargoUsuario = localStorage.getItem("role") || "vereador";

    const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    buscarDados();
  
    const intervalBusca = setInterval(buscarDados, 5000);
  
    return () => {
      clearInterval(intervalBusca);
      if (intervaloTribuna) clearInterval(intervaloTribuna);
    };
  }, []);  
  
    const fetchDados = async () => {  // Agora está no escopo global do componente
      try {
        const responseSessao = await axios.get(`${process.env.REACT_APP_API}/sessoes/sessao-atual`);
        setSessaoAtual(responseSessao.data);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };
    
    const buscarDados = async () => {
      try {
        const sessaoRes = await axios.get(`${process.env.REACT_APP_API}/sessoes/sessao-atual`);
    
        if (!sessaoRes.data || sessaoRes.data.status === "encerrada") {
          setSessao(null);
          setItensExibidos([]);
          setSessaoOrdem([]);
          setSessaoExpediente([]);
          setSessaoTribuna([]);
          return;
        }
    
        setSessao(sessaoRes.data);
    
        const [painelRes, ordemRes, expedienteRes, tribunaRes, pautasRes] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API}/painel-sessao`),
          axios.get(`${process.env.REACT_APP_API}/sessao-ordem/${sessaoRes.data.id}`),
          axios.get(`${process.env.REACT_APP_API}/sessao-expediente/${sessaoRes.data.id}`),
          axios.get(`${process.env.REACT_APP_API}/sessao-tribuna/${sessaoRes.data.id}`),
          axios.get(`${process.env.REACT_APP_API}/pautas`),
        ]);
    
        const itensEmPainel = painelRes.data || [];
        const idsBloqueados = itensEmPainel.map(item => item.itemId); // inclui exibidos e concluídos
    
        // só exibe os itens que **não estão no painel**
        setItensExibidos(itensEmPainel.filter(item => item.status !== "concluido"));
    
        setSessaoOrdem((ordemRes.data || []).filter(item => !idsBloqueados.includes(item.id)));
        setSessaoExpediente((expedienteRes.data || []).filter(item => !idsBloqueados.includes(item.id)));
        setSessaoTribuna((tribunaRes.data || []).filter(item => !idsBloqueados.includes(item.id)));
    
        const pautasNaoEnviadas = pautasRes.data.filter(pauta =>
          !ordemRes.data.some(ordem => ordem.pauta_id === pauta.id)
        );
        setPautasDisponiveis(pautasNaoEnviadas);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };    
    
    const adicionarPautaPainel = async (
      pautaId,
      titulo,
      minuta,
      autor,
      cargo,
      tipo = "expediente",
      itemId
    ) => {
      try {
        const jaTemTribuna = itensExibidos.some(item => item.tipo === "tribuna");
        const jaTemExpedienteOuOrdem = itensExibidos.some(
          item => item.tipo === "expediente" || item.tipo === "ordem"
        );
    
        if (tipo === "tribuna" && jaTemTribuna) {
          toast.warn("Finalize a exibição atual da tribuna para adicionar outro.");
          return;
        }
    
        if ((tipo === "expediente" || tipo === "ordem") && jaTemExpedienteOuOrdem) {
          toast.warn("Finalize a exibição atual para adicionar nova pauta.");
          return;
        }
    
        await axios.post(`${process.env.REACT_APP_API}/painel-sessao/adicionar`, {
          sessaoId: sessao.id,
          itemId,
          pautaId: pautaId || null,
          titulo: titulo || "Sem título",
          minuta: minuta || "Sem minuta",
          tipo,
          autor: autor || "Desconhecido",
          cargo: cargo || "Não informado",
          status: "aguardando"
        });
    
        // Só altera status da pauta se NÃO for tribuna
        if (tipo !== "tribuna" && pautaId) {
          await axios.post(`${process.env.REACT_APP_API}/pautas/${pautaId}/alterarStatus`, {
            status: "Encaminhado"
          });
        }
    
        buscarDados();
        toast.success("Pauta adicionada ao painel e status atualizado!");
      } catch (error) {
        console.error("Erro ao adicionar pauta ao painel:", error);
        toast.error(
          tipo === "tribuna"
            ? "Erro ao adicionar orador à tribuna."
            : "Erro ao adicionar a pauta!"
        );
      }
    };
    
  const removerPautaPainel = async (id) => {
    try {
      await axios.post(`${process.env.REACT_APP_API}/painel-sessao/concluir/${id}`);
      buscarDados(); // Atualiza a lista
      toast.success("Pauta removida do painel!");
    } catch (error) {
      console.error("Erro ao remover pauta do painel:", error);
      toast.error("Erro ao remover a pauta!");
    }
  };
  
  const alterarStatusItem = async (id, status) => {
    try {
      await axios.post(`${process.env.REACT_APP_API}/painel-sessao/${status}/${id}`);
      buscarDados();
    } catch (error) {
      console.error(`Erro ao alterar status para ${status}:`, error);
    }
  };

  useEffect(() => {
    const tribuna = itensExibidos.find(item => item.tipo === "tribuna" && item.dataHoraInicioFala);
  
    if (!tribuna) {
      setTempoRestante(null);
      if (intervaloTribuna) {
        clearInterval(intervaloTribuna);
        setIntervaloTribuna(null);
      }
      return;
    }
  
    const calcularTempo = () => {
      const inicio = new Date(tribuna.dataHoraInicioFala).getTime();
      const agora = Date.now();
      const usado = Math.floor((agora - inicio) / 1000);
      const restante = Math.max(900 - usado, 0);
      setTempoRestante(restante);
  
      if (restante <= 0) {
        if (intervaloTribuna) clearInterval(intervaloTribuna);
        removerPautaPainel(tribuna.id);
        toast.info("Tempo do orador finalizado e removido automaticamente.");
      }
    };
  
    calcularTempo(); // Executa de imediato
    const novoIntervalo = setInterval(calcularTempo, 1000);
    setIntervaloTribuna(novoIntervalo);
  
    return () => clearInterval(novoIntervalo);
  }, [itensExibidos]);

  const alterarStatusSessao = async (novoStatus) => {
    if (!sessao) return;
    try {
      await axios.patch(`${process.env.REACT_APP_API}/sessoes/${sessao.id}/status`, { status: novoStatus });
      buscarDados();
    } catch (error) {
      console.error("Erro ao alterar status da sessão:", error);
    }
  };

// Encerrar votação
const encerrarVotacao = async () => {
  try {
    const response = await axios.patch(`${process.env.REACT_APP_API}/votacoes/encerrar/${sessao.id}`, {
      status: "encerrada",
    });

    console.log("Votação encerrada com sucesso:", response.data);
    
    toast.success("Votação encerrada com sucesso!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
    });

    buscarDados(); // Atualiza a interface
  } catch (error) {
    console.error("Erro ao encerrar votação:", error.response?.data || error.message || error);

    toast.error("Erro ao encerrar votação. Verifique o console!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
    });
  }
};

  return (
    <>
    <div className={styles.painelContainer}>
   
  <nav className={styles.dockMenu}>
  <button className={styles.dockBtn} title="Voltar" onClick={() => navigate(-1)}>
              <FaArrowLeft />
            </button>
            <button className={styles.dockBtn} title="Dashboard" onClick={() => navigate("/dashboard")}>
              <FaHome />
            </button>
            <button className={styles.dockBtn} title="Controle da Sessão" onClick={() => navigate("/painel-controle")}>
              <FaChalkboardTeacher />
            </button>

    {/* 🔹 Botões extras - apenas para quem NÃO for vereador */}
    {cargoUsuario !== "vereador" && (
      <>
            <button className={styles.dockBtn} title="Registrar Presença" onClick={() => setModalPresencaOpen(true)}>
              <FaUsers />
            </button>
              <button className={styles.dockBtn} title="Configuração" onClick={() => navigate("/configuracao")}>
                <FaCogs />
              </button>
      </>
    )}

    <button className={styles.dockBtn} title="Logout" onClick={handleLogout}>
      <FaSignOutAlt />
    </button>
  </nav>


      <div className={styles.contentGerenciar}>
        <header className={styles.dashboardHeader}>
          <h2>{sessao ? `Sessão ${sessao.tipo} Nº ${sessao.numero}` : "Nenhuma sessão disponível"} - {sessao?.status || ""}</h2>
          <div className={styles.botoesControle}>
            <button onClick={() => alterarStatusSessao("aberta")} className={styles.botaoPlay} title="Abrir Sessão">
              <FaPlay /> Abrir
            </button>
            <button onClick={() => alterarStatusSessao("suspensa")} className={styles.botaoPause} title="Suspender Sessão">
              <FaPause /> Suspender
            </button>
            <button onClick={() => alterarStatusSessao("encerrada")} className={styles.botaoStop} title="Encerrar Sessão">
              <FaStop /> Encerrar
            </button>
          </div>
        </header>
        <div className={styles.painelGrid}>
        <section className={styles.painelExibicao}>
      <h3><FaPoll /> Votações</h3>
      <div className={styles.filtrosContainer}>
        {/* Botão Urna */}
        <button className={`${styles.filtroVotacao}`} onClick={() => setMostrarModalVotacao(true)}>
          <FaVoteYea className={`${styles.btnAcao} ${styles.btnExibir}`} /> <strong>Votação</strong>
        </button>
      </div>

   {/* 🔹 EXIBIÇÃO DO PAINEL DA SESSÃO */}
<h3> <FaTv /> Painel da Sessão</h3>

<div className={styles.votacaoContainer}>
  <div className={styles.listaVotacao}>
    <ul>
      {itensExibidos.length > 0 ? (
        itensExibidos.map((item) => (
          <li key={item.id} className={styles.painelItem}>
            <div className={styles.painelInfo}>
              <h4>{item.titulo}</h4>
              <p><FaTv /> <strong>Status:</strong> {item.status}</p>
            </div>

            <div className={styles.acoes}>
              {/* O botão "Exibir" só aparece se o item não estiver sendo exibido */}
              {item.status !== "exibindo" && (
                <button 
                  onClick={() => alterarStatusItem(item.id, "iniciar")} 
                  className={`${styles.btnAcao} ${styles.btnExibir}`}
                  title="Exibir no painel"
                >
                  <FaEye className={styles.iconeAcao} />
                </button>
              )}

              {/* O botão "Ocultar" só aparece se o item já estiver sendo exibido */}
              {item.status === "exibindo" && (
                <button 
                  onClick={() => alterarStatusItem(item.id, "ocultar")} 
                  className={`${styles.btnAcao} ${styles.btnEsconder}`}
                  title="Ocultar do painel"
                >
                  <FaEyeSlash className={styles.iconeAcao} />
                </button>
              )}

              <button 
                onClick={() => removerPautaPainel(item.id)} 
                className={`${styles.btnAcao} ${styles.btnRemover}`}
                title="Concluir e remover"
              >
                <FaCheck className={styles.iconeAcao} />
              </button>
            </div>
          </li>
        ))
      ) : (
        <p>🔹 Nenhum item em exibição.</p>
      )}
    </ul>
  </div>
</div>

      {/* 🔹 MODAL DE VOTAÇÃO */}
      {mostrarModalVotacao && (
        <ModalVotacao
          sessaoId={sessaoAtual?.id}
          fecharModal={() => setMostrarModalVotacao(false)}
          atualizarDados={buscarDados}
        />
      )}
    </section>
                  <section className={styles.section}>

                     {/* Filtros Estilizados */}
      <div className={styles.filtrosContainer}>
        <div className={`${styles.filtroCard} ${exibirExpediente ? styles.ativo : ""}`} onClick={() => setExibirExpediente(!exibirExpediente)}>
          <FaAlignLeft className={styles.filtroIcon} />
          <div>
            <div title="Clique para ativar ou desativar o filtro.">Expediente</div>
          </div>
        </div>

        <div className={`${styles.filtroCard} ${exibirOrdem ? styles.ativo : ""}`} onClick={() => setExibirOrdem(!exibirOrdem)}>
          <FaFileAlt className={styles.filtroIcon} />
          <div>
            <div  title="Clique para ativar ou desativar o filtro.">Ordem do Dia</div>
          </div>
        </div>

        <div className={`${styles.filtroCard} ${exibirTribuna ? styles.ativo : ""}`} onClick={() => setExibirTribuna(!exibirTribuna)}>
          <FaUsers className={styles.filtroIcon} />
          <div>
            <div  title="Clique para ativar ou desativar o filtro.">Tribuna</div>
          </div>
        </div>
      </div>


      {/* Seção: Expediente da Sessão */}
      {exibirExpediente && (
        <div className={styles.pautaGrupo}>
          <h3>Matérias do Expediente da Sessão</h3>
          <ul>
            {sessaoExpediente.length > 0 ? (
              sessaoExpediente.map((item) => (
                <li key={item.id}>
                  <strong><FaFileAlt /> {item.titulo} (Autoria: {item.autor})</strong>
                  <div className={styles.acoes}>
                  <button
onClick={() =>
  adicionarPautaPainel(
    item.pauta?.id,                 // ← Pauta real
    item.titulo,
    item.minuta,
    item.autor,
    item.pauta?.usuario?.cargo || "Não informado", // opcional
    "expediente",                   // ou "ordem"
    item.id                         // ID da sessão_expediente ou sessão_ordem
  )
}> <FaPlusCircle />
</button>
                  </div>
                </li>
              ))
            ) : (
              <p>🔹 Nenhuma pauta disponível.</p>
            )}
          </ul>
        </div>
      )}


            {/* Seção: Ordem do Dia */}
      {exibirOrdem && (
        <div className={styles.pautaGrupo}>
          <h3>Matérias da Ordem do Dia</h3>
          <ul>
            {sessaoOrdem.length > 0 ? (
              sessaoOrdem.map((item) => (
                <li key={item.id}>
                  <strong><FaFileAlt /> {item.titulo} (Autoria: {item.autor})</strong>
                  <div className={styles.acoes}>
                  <button
onClick={() =>  adicionarPautaPainel( item.pauta?.id, item.titulo, item.minuta, item.autor, item.pauta?.usuario?.cargo || "Não informado", // opcional
    "ordem", item.id ) }> <FaPlusCircle />
</button>
                  </div>
                </li>
              ))
            ) : (
              <p>🔹 Nenhuma pauta para votação.</p>
            )}
          </ul>
        </div>
      )}

      {/* Seção: Tribuna */}
      {exibirTribuna && (
        <div className={styles.pautaGrupo}>
          <h3>Tribuna</h3>
          <ul>
            {sessaoTribuna.length > 0 ? (
              sessaoTribuna.map((item) => (
                <li key={item.id}>
                  <strong>{item.nome} {item.tema} {item.autor}</strong>
                  <div className={styles.acoes}>
                  <button
  onClick={() =>
    adicionarPautaPainel(
      item.pauta?.id || null,    // pautaId (se tiver)
      item.tema,
      item.nome,
      item.autor,
      item.cargo,
      "tribuna",
      item.id                    // itemId (sessao_tribuna)
    )
  }
  title="Adicionar"
>
  <FaPlusCircle />
</button>
</div>
                </li>
              ))
            ) : (
              <p>🔹 Nenhuma fala na tribuna.</p>
            )}
          </ul>
        </div>
      )}
    </section>
        </div>

        {/* 🔹 Modal de Presença */}
        {modalPresencaOpen && sessao?.id && (
  <ModalPresenca sessaoId={sessao.id} closeModal={() => setModalPresencaOpen(false)} />
)}


        {mostrarModalVotacao && (
  <ModalVotacao 
    sessaoId={sessao?.id} 
    fecharModal={() => setMostrarModalVotacao(false)} 
    atualizarDados={buscarDados} 
  />
)}
              {/* Exibição dos alertas Toastify */}
      <ToastContainer />
      </div>

    </div>
    </>
    
  );
};

export default PainelControle;
