import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  FaHome,
  FaCogs,
  FaSignOutAlt,
  FaArrowLeft,
  FaCalendarAlt,
  FaCheckCircle
} from "react-icons/fa";
import styles from "./Styles.module.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const VotacoesGerenciar = () => {
  const navigate = useNavigate();
  const [votacoes, setVotacoes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseVotacoes = await axios.get(`${process.env.REACT_APP_API}/votacoes/todas`);
        console.log("📊 Votações:", responseVotacoes.data);
        setVotacoes(responseVotacoes.data);
      } catch (error) {
        console.error("Erro ao buscar votações:", error);
        toast.error("Erro ao buscar votações.");
      }
    };

    fetchData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className={styles.content}>
      <>
        <header className={styles.dashboardHeader}>
          <h3>Gerenciamento de Votações</h3>
          <img src="/logo.png" alt="Logo da Câmara" className={styles.logo} />
        </header>

        <nav className={styles.dockMenu}>
          <button className={styles.dockBtn} title="Voltar" onClick={() => navigate(-1)}>
            <FaArrowLeft />
          </button>
          <button className={styles.dockBtn} title="Dashboard" onClick={() => navigate("/dashboard")}>
            <FaHome />
          </button>
          <button className={styles.dockBtn} title="Configuração" onClick={() => navigate("/configuracao")}>
            <FaCogs />
          </button>
          <button className={styles.dockBtn} title="Logout" onClick={handleLogout}>
            <FaSignOutAlt />
          </button>
        </nav>

        <div className={styles.contentGerenciar}>
          <div className={styles.section}>
            <div className={styles.pautasGrid}>
              {votacoes.length > 0 ? (
                votacoes.map((votacao) => (
                  <div key={votacao.id} className={styles.pautaCard}>
                    <div className={styles.pautaInfo}>
                      <h3>{votacao.tipoVotacao}</h3>
                    </div>

                    <span className={styles.pautaStatus}>{votacao.status}</span>

                    <div>
                      <div className={styles.pautaDetalhe}>
                        <FaCheckCircle /> <strong>{votacao.descricao}</strong>
                      </div>

                      <div className={styles.pautaDetalhe}>
                        <FaCalendarAlt className={styles.icon} />
                        <p>
                          <strong>Votação em</strong> {new Date(votacao.votacao).toLocaleString("pt-BR")}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>Nenhuma votação encontrada.</p>
              )}
            </div>
          </div>
        </div>

        <ToastContainer />
      </>
    </div>
  );
};

export default VotacoesGerenciar;
