import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./Sessao.module.css";
import { 
  FaArrowLeft, FaClock, FaCogs, FaCalendarAlt, FaSignOutAlt, FaUsers, FaCheckCircle, FaTimesCircle, 
  FaVoteYea, FaFileAlt, FaUserCheck, FaScroll, FaTv, FaBars, FaTimes, FaHome, FaChalkboardTeacher
} from "react-icons/fa";

const Sessao = () => {
  const { id: sessaoId } = useParams(); // 🔹 Captura corretamente o ID da sessão
  const navigate = useNavigate();
  const [sessao, setSessao] = useState(null);
  const [presencas, setPresencas] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    if (!sessaoId) return;

    // 🔹 Buscar detalhes da sessão
    axios.get(`${process.env.REACT_APP_API}/sessoes/${sessaoId}`)
      .then(response => {
        setSessao(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error("Erro ao buscar sessão:", error);
        setLoading(false);
      });

    // 🔹 Buscar presenças dos vereadores na sessão
    axios.get(`${process.env.REACT_APP_API}/presencas/sessao/${sessaoId}`)
      .then(response => {
        setPresencas(response.data);
        console.log("Presenças carregadas:", response.data);
      })
      .catch(error => console.error("Erro ao buscar presenças:", error));

  }, [sessaoId]);

  if (loading) {
    return <div className={styles.loading}>Carregando...</div>;
  }

  if (!sessao) {
    return <div className={styles.notFound}>Sessão não encontrada.</div>;
  }

  return (

    <div className={styles.container}>
          <>
           {/* 🔹 Menu inferior fixo estilo Dock */}

          
      {/* Painel Principal */}
      <div className={styles.painelContainer}>
                        <header className={styles.dashboardHeader}>
                        <h3>Gerenciamento de Sessões</h3>
                          <img src="/logo.png" alt="Logo da Câmara" className={styles.logo} />
                        </header>


        <header className={styles.header}>
          <h1>Sessão {sessao.tipo} - Nº {sessao.numero}</h1>
          <p><FaCalendarAlt /> {new Date(sessao.data).toLocaleDateString()}</p>
          <span className={`${styles.status} ${styles[sessao.status]}`}>
            {sessao.status === "aberta" ? <FaCheckCircle /> : <FaTimesCircle />}
            {sessao.status}
          </span>
        </header>

            <nav className={styles.dockMenu}>
              <button className={styles.dockBtn} title="Voltar" onClick={() => navigate(-1)}>
                <FaArrowLeft />
              </button>
              <button className={styles.dockBtn} title="Dashboard" onClick={() => navigate("/dashboard")}>
                <FaHome />
              </button>
              <button className={styles.dockBtn} title="Controle da Sessão" onClick={() => navigate("/painel-controle")}>
                <FaChalkboardTeacher />
              </button>
              <button className={styles.dockBtn} title="Configuração" onClick={() => navigate("/configuracao")}>
                <FaCogs />
              </button>
              <button className={styles.dockBtn} title="Logout" onClick={handleLogout}>
                <FaSignOutAlt />
              </button>
            </nav>

        {/* Informações Gerais */}
        <div className={styles.sessaoInfo}>
          <div className={styles.infoCard}>
            <FaClock className={styles.icon} />
            <p><strong>Início:</strong> {sessao.inicio ? new Date(sessao.inicio).toLocaleTimeString() : "Não iniciado"}</p>
            <p><strong>Fim:</strong> {sessao.fim ? new Date(sessao.fim).toLocaleTimeString() : "Em andamento"}</p>
          </div>

          <div className={styles.infoCard}>
            <FaUserCheck className={styles.icon} />
            <p><strong>Vereadores Presentes:</strong> {presencas.filter(p => p.presente === 1).length} / {presencas.length}</p>
          </div>

          <div className={styles.infoCard}>
            <FaVoteYea className={styles.icon} />
            <p><strong>Votações Realizadas:</strong> {sessao.votacoes?.length || 0}</p>
          </div>
        </div>

        {/* 🔹 Lista de Presença */}
        <div className={styles.section}>
          <h2><FaUsers /> Presença dos Vereadores</h2>
          <ul className={styles.listaPresenca}>
            {presencas.length > 0 ? (
              presencas.map((presenca) => (
                <li key={presenca.id} className={styles.vereadorItem}>
                  {presenca.usuario.nome} -{" "}
                  {presenca.presente === 1 ? (
                    <span className={styles.presente}>
                      <FaCheckCircle className={styles.presenteIcon} /> Presente
                    </span>
                  ) : (
                    <span className={styles.ausente}>
                      <FaTimesCircle className={styles.ausenteIcon} /> Ausente
                      {presenca.motivo && (
                        <span className={styles.motivoAusencia}> - {presenca.motivo}</span>
                      )}
                    </span>
                  )}
                </li>
              ))
            ) : (
              <p>🔹 Nenhum vereador presente registrado.</p>
            )}
          </ul>
        </div>

        {/* Ordem do Dia */}
        <div className={styles.section}>
          <h2><FaScroll /> Ordem do Dia</h2>
          <ul>
            {sessao.ordemDoDia?.length > 0 ? sessao.ordemDoDia.map((item) => (
              <li key={item.id}><FaFileAlt /> {item.titulo || "Sem título"}</li>
            )) : <p>🔹 Nenhuma pauta na Ordem do Dia.</p>}
          </ul>
        </div>

        {/* Expediente */}
        <div className={styles.section}>
          <h2><FaFileAlt /> Expediente</h2>
          <ul>
            {sessao.expediente?.length > 0 ? sessao.expediente.map((item) => (
              <li key={item.id}><FaFileAlt /> {item.titulo || "Sem título"} - {item.autor}</li>
            )) : <p>🔹 Nenhum expediente registrado.</p>}
          </ul>
        </div>

        {/* Tribuna */}
        <div className={styles.section}>
          <h2><FaUsers /> Tribuna</h2>
          <ul>
            {sessao.tribuna?.length > 0 ? sessao.tribuna.map((item) => (
              <li key={item.id}><FaUserCheck /> {item.tema || "Sem tema"}</li>
            )) : <p>🔹 Nenhuma fala na tribuna.</p>}
          </ul>
        </div>
      </div>
      </>
    </div>

  );
};

export default Sessao;
