import React, { useState, useEffect } from "react";
import styles from "./Modal.module.css";
import { FaFolderOpen, FaTrash, FaTimes } from "react-icons/fa";

const ModalEditarPauta = ({ pautaId, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    titulo: "",
    numero: "",
    tipo: "",
    status: "",
    minuta: "",
    texto_completo: "",
    anexo: null,
    tipoAnexo: "", // ✅ Campo do tipo do anexo principal
    autor: "",
    comissaoId: "",
  });

  const [loading, setLoading] = useState(true);
  const [comissoes, setComissoes] = useState([]);
  const [parecer, setParecer] = useState(null);
  const [anexosExtras, setAnexosExtras] = useState([{ file: null, tipo: "" }]);

  const handleAnexoChange = (index, file) => {
    const newAnexos = [...anexosExtras];
    newAnexos[index].file = file;
    setAnexosExtras(newAnexos);
  };

  const handleTipoChange = (index, tipo) => {
    const newAnexos = [...anexosExtras];
    newAnexos[index].tipo = tipo;
    setAnexosExtras(newAnexos);
  };

  const handleRemoveAnexo = (index) => {
    const newAnexos = anexosExtras.filter((_, i) => i !== index);
    setAnexosExtras(newAnexos);
  };

  const enviarAnexosExtras = async () => {
    const anexosForm = new FormData();
    anexosExtras.forEach((anexo) => anexosForm.append("anexos", anexo.file));
    anexosForm.append("tipos", anexosExtras.map((a) => a.tipo));

    try {
      const response = await fetch(`${process.env.REACT_APP_API}/pautas/${pautaId}/anexos`, {
        method: "POST",
        body: anexosForm,
      });

      if (!response.ok) throw new Error("Erro ao enviar anexos adicionais.");

      console.log("✅ Anexos extras enviados com sucesso!");
    } catch (error) {
      console.error("❌ Erro ao enviar anexos adicionais:", error);
      alert("Erro ao enviar anexos adicionais.");
    }
  };

  useEffect(() => {
    if (!pautaId) return;

    fetch(`${process.env.REACT_APP_API}/pautas/${pautaId}`)
      .then((res) => res.json())
      .then((data) => {
        setFormData({
          titulo: data.titulo,
          numero: data.numero,
          tipo: data.tipo,
          status: data.status,
          minuta: data.minuta,
          texto_completo: data.texto_completo,
          anexo: data.anexo,
          tipoAnexo: data.tipoAnexo || "",
          autor: data.autor,
          comissaoId: data.comissao ? data.comissao.id : "",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.error("Erro ao buscar pauta:", err);
        setLoading(false);
      });

    fetch(`${process.env.REACT_APP_API}/comissoes`)
      .then((res) => res.json())
      .then((data) => setComissoes(data))
      .catch((err) => console.error("Erro ao buscar comissões:", err));
  }, [pautaId]);

  const handleParecerChange = (e) => {
    const file = e.target.files[0];
    if (file) setParecer(file);
  };

  const enviarParecer = async (pautaId) => {
    const parecerFormData = new FormData();
    parecerFormData.append("parecer", parecer);

    try {
      const response = await fetch(`${process.env.REACT_APP_API}/pautas/${pautaId}/anexar-parecer`, {
        method: "POST",
        body: parecerFormData,
      });

      if (!response.ok) throw new Error("Erro ao enviar parecer.");

      const data = await response.json();
      console.log("✅ Parecer enviado com sucesso!", data);
    } catch (error) {
      console.error("❌ Erro ao enviar parecer:", error);
      alert("Erro ao enviar parecer.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) setFormData({ ...formData, anexo: file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("titulo", formData.titulo);
    formDataToSend.append("numero", formData.numero);
    formDataToSend.append("tipo", formData.tipo);
    formDataToSend.append("status", formData.status);
    formDataToSend.append("minuta", formData.minuta);
    formDataToSend.append("texto_completo", formData.texto_completo);
    formDataToSend.append("autor", formData.autor);
    formDataToSend.append("comissaoId", formData.comissaoId);
    formDataToSend.append("tipoAnexo", formData.tipoAnexo);

    if (formData.anexo && typeof formData.anexo !== "string") {
      formDataToSend.append("anexo", formData.anexo);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API}/pautas/${pautaId}`, {
        method: "PUT",
        body: formDataToSend,
      });

      if (parecer) await enviarParecer(pautaId);
      if (anexosExtras.some((a) => a.file)) await enviarAnexosExtras();

      if (!response.ok) throw new Error("Erro ao atualizar pauta.");

      alert("Pauta atualizada com sucesso!");
      onSave();
      onClose();
    } catch (error) {
      console.error("Erro ao salvar alterações:", error);
      alert("Erro ao salvar alterações.");
    }
  };

  if (!pautaId) return null;
  if (loading) return <div className={styles.loading}>Carregando...</div>;

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <button className={styles.modalClose} onClick={onClose}>
                  <FaTimes />
                </button>
        <h2 className={styles.modalTitle}>Editar Pauta</h2>

        <form onSubmit={handleSubmit} className={styles.formPautaAdd}>
        <div className={styles.inputGroup}>
              <label>Tipo</label>
              <select name="tipo" value={formData.tipo} onChange={handleChange}>
              <option value="">Selecione o tipo</option>
                <option value="Projeto de Lei">Projeto de Lei</option>
                <option value="Requerimento">Requerimento</option>
                <option value="Moção">Moção</option>
                <option value="Indicação">Indicação</option>
              </select>
            </div>

          <div className={styles.row}>
            <div className={styles.inputGroup}>
              <label>Título</label>
              <input type="text" name="titulo" value={formData.titulo} onChange={handleChange} required />
            </div>
          </div>
          <div className={styles.row}>

          <div className={styles.inputGroup}>
          <label>Autoria</label>
          <input type="text" name="autor" value={formData.autor} onChange={handleChange} />
        </div>
            <div className={styles.inputGroup}>
              <label>Tramitação</label>
              <select name="status" value={formData.status} onChange={handleChange}>
              <option value="">Atualizar tramitação</option>
                <option value="novo">Novo</option>
                <option value="Analisado">Analisado</option>
                <option value="Rejeitado">Rejeitado</option>
                <option value="Aprovado">Aprovado</option>
                <option value="Aguardando Votação">Aguardando Votação</option>
              </select>
            </div>
          </div>

        <div className={styles.row}>
        <div className={styles.inputGroup}>
          <label>Minuta</label>
          <textarea name="minuta" value={formData.minuta} onChange={handleChange} />
          </div>
          <div className={styles.inputGroup}>
          <label>Texto Completo</label>
          <textarea name="texto_completo" value={formData.texto_completo} onChange={handleChange} />
          </div>
        </div>
        <div className={styles.row}>
            <div className={styles.inputGroup}>
              <label htmlFor="anexoUpload">📎 Anexo Principal</label>
              <label htmlFor="anexoUpload" className={styles.uploadButton}>
                <FaFolderOpen className="icon" />
                {formData.anexo?.name || (typeof formData.anexo === "string" ? formData.anexo : "Selecionar Arquivo")}
              </label>
              <input
                id="anexoUpload"
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <select name="tipoAnexo" value={formData.tipoAnexo} onChange={handleChange}>
                <option value="">Tipo do anexo principal</option>
                <option value="Documento Original">Documento Original</option>
                <option value="Parecer da Comissão">Parecer da Comissão</option>
                <option value="Diário Oficial">Diário Oficial</option>
                <option value="Outros">Outros</option>
              </select>
            </div>

{/* Parecer */}
<div className={styles.inputGroup}>
  <label htmlFor="parecerUpload">📝 Parecer</label>
  <label htmlFor="parecerUpload" className={styles.uploadButton}>
    <FaFolderOpen className="icon" />
    {parecer?.name || "Selecionar Arquivo"}
  </label>
  <input
    id="parecerUpload"
    type="file"
    onChange={handleParecerChange}
    style={{ display: "none" }}
  />
</div>
</div>

        <div className={styles.row}>
          <div className={styles.modalActions}>
            <button className={styles.btnSim} type="submit">Salvar</button>
            <button type="button" className={styles.btnNao} onClick={onClose}>Cancelar</button>
          </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalEditarPauta;
