import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./Modal.module.css";
import { FaVoteYea, FaTimes, FaPoll } from "react-icons/fa";
import { toast } from "react-toastify";
import ModalResultados from "./ModalResultados";
import { useSocket } from "../../../src/contexts/SocketContext";

const ModalVotacao = ({ sessaoId, fecharModal, onClose }) => {
  const socket = useSocket();
  const [sessaoAtual, setSessaoAtual] = useState(null);
  const [ordemDoDia, setOrdemDoDia] = useState([]);
  const [expediente, setExpediente] = useState([]);
  const [pautasSelecionadas, setPautasSelecionadas] = useState(new Set());
  const [tipoVotacao, setTipoVotacao] = useState("");
  const [loading, setLoading] = useState(true);
  const [pautasVotadasIds, setPautasVotadasIds] = useState(new Set());
  const [votacoesAndamento, setVotacoesAndamento] = useState([]);
  const [mostrarModalResultados, setMostrarModalResultados] = useState(false);

  useEffect(() => {
    const carregarDados = async () => {
      try {
        setLoading(true);
        let id = sessaoId;

        if (!id) {
          const { data } = await axios.get(`${process.env.REACT_APP_API}/sessoes/sessao-atual`);
          if (!data?.id) {
            toast.warn("⚠️ Nenhuma sessão ativa encontrada.");
            return;
          }
          id = data.id;
        }

        setSessaoAtual({ id });

        const [ordemRes, expedienteRes, votacoesRes] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API}/sessao-ordem/${id}`),
          axios.get(`${process.env.REACT_APP_API}/sessao-expediente/${id}`),
          axios.get(`${process.env.REACT_APP_API}/votacoes/sessao/${id}`)
        ]);

        const votadas = (votacoesRes.data || []).filter(v => v.status !== null);
        const idsVotadas = new Set(votadas.map(v => v.pautaId));

        setPautasVotadasIds(idsVotadas);
        setOrdemDoDia(ordemRes.data || []);
        setExpediente(expedienteRes.data || []);

        // Busca votações em andamento
        const andamento = (votacoesRes.data || []).filter(v => v.status === "andamento");
        const votosPromises = andamento.map(async (votacao) => {
          const votos = await axios.get(`${process.env.REACT_APP_API}/voto/votacao/${votacao.id}`);
          return { votacao, votos: votos.data };
        });
        const resultado = await Promise.all(votosPromises);
        setVotacoesAndamento(resultado);
      } catch (error) {
        console.error("Erro ao carregar dados:", error);
        toast.error("Erro ao carregar dados.");
      } finally {
        setLoading(false);
      }
    };

    carregarDados();
  }, [sessaoId]);

  useEffect(() => {
    if (!socket) return;
  
    const handleVotoRecebido = (voto) => {
      setVotacoesAndamento((prev) =>
        prev.map(({ votacao, votos }) => {
          if (votacao.id === voto.votacao?.id || votacao.id === voto.votacaoId) {
            if (!votos.some((v) => v.id === voto.id)) {
              return {
                votacao,
                votos: [...votos, voto],
              };
            }
          }
          return { votacao, votos };
        })
      );
    };
  
    socket.on("voto-registrado", handleVotoRecebido);
  
    return () => {
      socket.off("voto-registrado", handleVotoRecebido);
    };
  }, [socket]);
  
  const alternarSelecaoPauta = (pautaId) => {
    setPautasSelecionadas((prev) => {
      const novoSet = new Set(prev);
      if (novoSet.has(pautaId)) {
        novoSet.delete(pautaId);
      } else {
        novoSet.add(pautaId);
      }
      return novoSet;
    });
  };

  const criarVotacao = async () => {
    if (!tipoVotacao) return toast.warn("Selecione um tipo de votação.");
    if (pautasSelecionadas.size === 0) return toast.warn("Selecione ao menos uma pauta.");

    const id = sessaoAtual?.id || sessaoId;
    if (!id) return toast.error("Sessão inválida.");

    const pautaIds = Array.from(pautasSelecionadas);

    try {
      const res = await axios.post(`${process.env.REACT_APP_API}/votacoes/iniciar`, {
        sessaoId: id,
        pautaIds,
        tipoVotacao,
        votacao: new Date().toISOString()
      });

      toast.success("Votação(ões) criada(s) com sucesso!");

      // Pequeno delay para garantir que a votação foi salva
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Buscar novamente votações em andamento após criação
      const votacoesAtualizadas = await axios.get(`${process.env.REACT_APP_API}/votacoes/sessao/${id}`);
      const andamento = (votacoesAtualizadas.data || []).filter(v => v.status === "andamento");

      const votosPromises = andamento.map(async (votacao) => {
        const votos = await axios.get(`${process.env.REACT_APP_API}/voto/votacao/${votacao.id}`);
        return { votacao, votos: votos.data };
      });

      const resultado = await Promise.all(votosPromises);
      setVotacoesAndamento(resultado);
    } catch (error) {
      console.error("Erro ao criar votação:", error.response?.data || error.message);
      toast.error("Erro ao criar votação.");
    }
  };

  const renderListaPautas = (lista, tipo) =>
    lista
      .filter(item => {
        const pautaId = item?.pauta?.id || item.pauta_id;
        return !pautasVotadasIds.has(pautaId);
      })
      .map((item, index) => {
        const pautaId = item?.pauta?.id || item.pauta_id;
        return (
          <li key={`${tipo}-${pautaId}-${index}`}>
            <input
              type="checkbox"
              checked={pautasSelecionadas.has(pautaId)}
              onChange={() => alternarSelecaoPauta(pautaId)}
            />
            {item.titulo} ({tipo})
          </li>
        );
      });

  const renderVotacoesAtivas = () =>
    votacoesAndamento.map(({ votacao, votos }) => (
      <div key={votacao.id} className={styles.votacaoAtivaBox}>
        <h4>{votacao.descricao || `Votação #${votacao.id}`}</h4>
        {votos.length === 0 ? (
          <p>Aguardando votos...</p>
        ) : (
          <ul>
            {votos.map((v) => (
              <li key={v.id}>
                <strong>{v.usuario?.nome || v.usuarioNome}:</strong> {v.escolha}
              </li>
            ))}
          </ul>
        )}
      </div>
    ));

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
                <button className={styles.modalClose} onClick={onClose}>
                  <FaTimes />
                </button>
        <div className={styles.inputGroup}>
          <h3>Nova Votação</h3>

          {loading ? (
            <p>Carregando pautas...</p>
          ) : votacoesAndamento.length > 0 ? (
            <div>
              <h4>🗳️ Votação em Andamento</h4>
              {renderVotacoesAtivas()}
              <div className={styles.modalActions}>
                <button onClick={() => setMostrarModalResultados(true)}>
                  <FaPoll className={`${styles.btnAcao}`} /> <strong>Resultados</strong>
                </button>
                <button className={styles.btnCancelar} onClick={fecharModal}>
                  <FaTimes /> Fechar
                </button>
              </div>
            </div>
          ) : (
            <>
              <label>Tipo de Votação:</label>
              <div className={styles.seletorContainer}>
                {["Maioria Absoluta", "Maioria Simples", "Maioria Qualificada"].map((tipo) => (
                  <div
                    key={tipo}
                    className={`${styles.seletorCard} ${tipoVotacao === tipo ? styles.ativo : ""}`}
                    onClick={() => setTipoVotacao(tipo)}
                  >
                    {tipo}
                  </div>
                ))}
              </div>

              <h4>Pautas Disponíveis</h4>
              {ordemDoDia.length === 0 && expediente.length === 0 ? (
                <p>Nenhuma pauta disponível.</p>
              ) : (
                <ul className={styles.listaPautas}>
                  {renderListaPautas(ordemDoDia, "Ordem do Dia")}
                  {renderListaPautas(expediente, "Expediente")}
                </ul>
              )}

              <div className={styles.modalActions}>
                <button className={styles.btnAvancar} onClick={criarVotacao}>
                  <FaVoteYea /> Criar Votação
                </button>
                <button className={styles.btnCancelar} onClick={fecharModal}>
                  <FaTimes /> Cancelar
                </button>
              </div>
            </>
          )}
        </div>

        {mostrarModalResultados && (
          <ModalResultados
            sessaoId={sessaoAtual?.id}
            fecharModal={() => setMostrarModalResultados(false)}
          />
        )}
      </div>
    </div>
  );
};

export default ModalVotacao;
