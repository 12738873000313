import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import styles from './Styles.module.css';
import { 
    FaHome, FaChalkboardTeacher, FaCogs, FaSignOutAlt, 
    FaCalendarAlt, FaInfoCircle, FaCheckCircle, FaTimesCircle, 
    FaSearch, FaFilter, FaArrowLeft, FaUsers, FaEye, FaBars
} from "react-icons/fa";

const SessaoDetalhes = () => {
    const [sessoes, setSessoes] = useState([]);
    const [statusFiltro, setStatusFiltro] = useState('');
    const [termoBusca, setTermoBusca] = useState('');
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem("token");
        navigate("/login");
      }    
    
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}/sessoes`)
            .then(response => {
                if (Array.isArray(response.data)) {
                    setSessoes(response.data);
                } else {
                    console.error('A resposta não é um array válido');
                }
            })
            .catch(error => {
                console.error('Erro ao buscar sessões:', error);
            });
    }, []);

    // Contador de sessões por status
    const contarSessoes = (status) => sessoes.filter(sessao => sessao.status === status).length;

    // Filtro de sessões
    const sessoesFiltradas = sessoes.filter(sessao => 
        (statusFiltro ? sessao.status === statusFiltro : true) &&
        (termoBusca === "" ||
            sessao.numero?.toString().includes(termoBusca) ||
            sessao.tipo?.toLowerCase().includes(termoBusca.toLowerCase()) ||
            sessao.status?.toLowerCase().includes(termoBusca.toLowerCase()))
    );

    return (
          
        <div className={styles.content}>
 <>
            {/* Painel Principal */}
        <header className={styles.dashboardHeader}>
        <h3>Sessões Legislativas</h3>
          <img src="/logo.png" alt="Logo da Câmara" className={styles.logo} />
        </header>
                
  <nav className={styles.dockMenu}>
    <button className={styles.dockBtn} title="Voltar" onClick={() => navigate(-1)}>
      <FaArrowLeft />
    </button>
    <button className={styles.dockBtn} title="Dashboard" onClick={() => navigate("/dashboard")}>
      <FaHome />
    </button>
    <button className={styles.dockBtn} title="Controle da Sessão" onClick={() => navigate("/painel-controle")}>
      <FaChalkboardTeacher />
    </button>
    <button className={styles.dockBtn} title="Configuração" onClick={() => navigate("/configuracao")}>
      <FaCogs />
    </button>
    <button className={styles.dockBtn} title="Logout" onClick={handleLogout}>
      <FaSignOutAlt />
    </button>
  </nav>

                {/* 🔹 Filtros de Sessões */}
                <div className={styles.contentGerenciar}>
                <div className={styles.filtrosContainer}>
                 
                    {/* 🔹 Campo de busca */}
                    <div className={styles.filtroCard}>
                        <FaSearch className={styles.filtroIcon} />
                        <input
                            type="text"
                            placeholder="Buscar por número, tipo ou status..."
                            value={termoBusca}
                            onChange={(e) => setTermoBusca(e.target.value)}
                        />
                    </div>
                </div>

                {/* 🔹 Grid de Sessões */}
<div className={styles.sessoesGrid}>
  {sessoesFiltradas.length > 0 ? (
    sessoesFiltradas.map(sessao => {
      return (
        <div 
          key={sessao.id} 
          className={styles.sessaoCard} 
          data-status={sessao.status} 
          onClick={() => navigate(`/sessao/${sessao.id}`)}
        >
          {/* 🔹 Cabeçalho do Card */}
          <div className={styles.sessaoInfo}>
            <h3>Sessão {sessao.tipo || 'Tipo não disponível'}</h3>
          </div>

          {/* 🔹 Status da Sessão */}
          <span className={styles.sessaoStatus}>{sessao.status}</span>

          {/* 🔹 Informações */}
          <div>
          <div className={styles.sessaoNumero}>
            <FaCalendarAlt className={styles.icon} />
           <strong>Nº:</strong> {sessao.numero || 'Número não disponível'}
          </div>

          <div className={styles.sessaoNumero}>
            <FaCalendarAlt className={styles.icon} />
            <strong>Data:</strong> {sessao.data ? new Date(sessao.data).toLocaleDateString() : 'Data não disponível'}
          </div>
          </div>
        </div>
      );
    })
  ) : (
    <p>Não há sessões disponíveis no momento.</p>
  )}
</div>

            </div>
        </>
        </div>
    );
};

export default SessaoDetalhes;
