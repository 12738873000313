import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaHome, FaCalendarPlus, FaUsers, FaArrowLeft, FaChalkboardTeacher, FaCogs, FaSignOutAlt, FaUserPlus
} from "react-icons/fa";
import styles from "./Styles.module.css";

const Comissoes = () => {
  const navigate = useNavigate();
  const [comissoes, setComissoes] = useState([]);
  const [pautas, setPautas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedComissaoId, setSelectedComissaoId] = useState(null);

    const handleLogout = () => {
      localStorage.removeItem("token");
      navigate("/login");
    };

  useEffect(() => {
    const fetchComissoes = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/comissoes`);
        setComissoes(response.data);
      } catch (error) {
        console.error("Erro ao buscar comissões:", error);
      }
    };
    fetchComissoes();
  }, []);
  

  // Função para buscar as pautas vinculadas a uma comissão específica
  const fetchPautasPorComissao = async (comissaoId) => {
    setLoading(true);
    try {
      // Primeira consulta: obter as pautas vinculadas à comissão
      const response = await axios.get(`${process.env.REACT_APP_API}/pautas-comissoes?comissaoId=${comissaoId}`);
      const pautaIds = response.data.map((pautaComissao) => pautaComissao.pautaId);

      // Segunda consulta: obter os detalhes das pautas
      if (pautaIds.length > 0) {
        const pautasResponse = await axios.get(`${process.env.REACT_APP_API}/pautas`, {
          params: { ids: pautaIds }
        });
        setPautas(pautasResponse.data);
      }
    } catch (error) {
      console.error("Erro ao buscar pautas:", error);
    } finally {
      setLoading(false);
    }
  };

  // Navegação e visualização de detalhes da comissão
  const handleComissaoClick = (comissaoId) => {
    setSelectedComissaoId(comissaoId);
    fetchPautasPorComissao(comissaoId);
    navigate(`/comissoes/${comissaoId}`);
  };

  return (
  
      <main className={styles.painelContainer}>

<>
             {/* 🔹 Menu inferior fixo estilo Dock */}

          <nav className={styles.dockMenu}>
            <button className={styles.dockBtn} title="Voltar" onClick={() => navigate(-1)}>
              <FaArrowLeft />
            </button>
            <button className={styles.dockBtn} title="Dashboard" onClick={() => navigate("/dashboard")}>
              <FaHome />
            </button>
            <button className={styles.dockBtn} title="Controle da Sessão" onClick={() => navigate("/painel-controle")}>
              <FaChalkboardTeacher />
            </button>
            <button className={styles.dockBtn} title="Configuração" onClick={() => navigate("/configuracao")}>
              <FaCogs />
            </button>
            <button className={styles.dockBtn} title="Logout" onClick={handleLogout}>
              <FaSignOutAlt />
            </button>
          </nav>

        <header className={styles.dashboardHeader}>
        <h3>Gerenciamento de Comissões</h3>
          <img src="/logo.png" alt="Logo da Câmara" className={styles.logo} />
        </header>
        
        <div className={styles.cardContainer}>
        {Array.isArray(comissoes) && comissoes.map((comissao) => (
            <div
              key={comissao.id}
              className={styles.comissaoCard}
              onClick={() => handleComissaoClick(comissao.id)} // Atualiza a comissão selecionada
            >
              <FaUsers className={styles.iconTitle}/>
              <h3>{comissao.nome}</h3>
            </div>
          ))}
        </div>

        {selectedComissaoId && (
          <div className={styles.pautasList}>
            <h2>Pautas vinculadas à Comissão {selectedComissaoId}</h2>
            {loading ? (
              <p>Carregando pautas...</p>
            ) : (
              <div className={styles.cardContainer}>
                {pautas.map((pauta) => (
                  <div key={pauta.id} className={styles.pautaCard}>
                    <h3>{pauta.titulo}</h3>
                    <p><strong>Autor:</strong> {pauta.autor}</p>
                    <p><strong>Status:</strong> {pauta.status}</p>
                    <p><strong>Tipo:</strong> {pauta.tipo}</p>
                    <p><strong>Minuta:</strong> {pauta.minuta?.substring(0, 100)}...</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
            </>
      </main>

  );
};

export default Comissoes;
