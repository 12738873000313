import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./PainelSessao.module.css";

const PainelSessao = () => {
  const [votos, setVotos] = useState([]);
  const [sessao, setSessao] = useState(null);
  const [painelItens, setPainelItens] = useState([]);
  const [votacaoAtiva, setVotacaoAtiva] = useState(null);
  const [totalVereadores, setTotalVereadores] = useState(0);
  const [tempoSessao, setTempoSessao] = useState(0);
  const [minuta, setMinuta] = useState("");
  const [votacoesAtivas, setVotacoesAtivas] = useState([]);
  const [votacaoAtualIndex, setVotacaoAtualIndex] = useState(0);
  const [votacaoAtual, setVotacaoAtual] = useState(null);
  const [inicioFala, setInicioFala] = useState({});
  const [statusAnterior, setStatusAnterior] = useState({});
  const [temposVereadores, setTemposVereadores] = useState({});
  
  const buscarVotos = async (sessaoId) => {
    if (!sessaoId) return;

    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/voto/sessao?sessaoId=${sessaoId}`);
      console.log("📌 Votos recebidos para a sessão:", sessaoId, response.data);

      if (response.data.length > 0) {
        setVotos(response.data);  // Atualiza apenas se houver votos
      }
    } catch (error) {
      console.error("Erro ao buscar votos:", error);
    }
  };

  useEffect(() => {
    if (sessao?.id) {
      buscarVotos(sessao.id);
    }
  }, [sessao?.id]);

  useEffect(() => {
    const buscarVotacaoAtiva = async () => {
      try {
        const { data: votacoes } = await axios.get(`${process.env.REACT_APP_API}/votacoes`);
        const votacoesFiltradas = votacoes.filter(vot => vot.sessaoId === sessao?.id && vot.status === "andamento");
        setVotacoesAtivas(votacoesFiltradas);

        if (votacoesFiltradas.length > 0) {
          setVotacaoAtual(votacoesFiltradas[0]);
          buscarVotos(sessao?.id);
        } else {
          setVotacaoAtual(null);
          setVotos([]);
        }
      } catch (error) {
        console.error("Erro ao buscar votações ativas:", error);
      }
    };

    buscarVotacaoAtiva();
    const interval = setInterval(buscarVotacaoAtiva, 5000);
    return () => clearInterval(interval);
  }, [sessao]);

  useEffect(() => {
    if (votacoesAtivas.length > 1) {
      const interval = setInterval(() => {
        setVotacaoAtualIndex((prevIndex) => {
          const newIndex = (prevIndex + 1) % votacoesAtivas.length;
          setVotacaoAtual(votacoesAtivas[newIndex]);
          buscarVotos(sessao?.id);
          return newIndex;
        });
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [votacoesAtivas]);

  const calcularPorcentagemRestante = (vereador) => {
    const restante = calcularTempoRestante(vereador);
    return Math.max(0, Math.min(100, (restante / tempoTotalFala) * 100));
  };

  useEffect(() => {
    const fetchDados = async () => {
      try {
        const [sessaoRes, painelRes, pautasRes, votacaoRes, camaraRes, votosRes] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API}/sessoes/sessao-atual`),
          axios.get(`${process.env.REACT_APP_API}/painel-sessao`),
          axios.get(`${process.env.REACT_APP_API}/pautas`),
          axios.get(`${process.env.REACT_APP_API}/votacoes`),
          axios.get(`${process.env.REACT_APP_API}/camara-municipal`),
          axios.get(`${process.env.REACT_APP_API}/voto/sessao?sessaoId=80`), // Rota correta para votos
        ]);

        setSessao(sessaoRes.data);
        setTotalVereadores(camaraRes.data.totalVereadores || 0);
        setPainelItens(painelRes.data.filter(item => Number(item.sessaoId) === Number(sessaoRes.data.id)));

        if (sessaoRes.data.status === "aberta" && sessaoRes.data.inicio) {
          setTempoSessao(Math.floor((Date.now() - new Date(sessaoRes.data.inicio).getTime()) / 1000));
        }

        const votacaoAtiva = votacaoRes.data.find(votacao => votacao.sessaoId === sessaoRes.data.id && votacao.status === "andamento");
        setVotacaoAtiva(votacaoAtiva || null);

        if (votacaoAtiva) {
          const votosFiltrados = votosRes.data.filter(voto => voto.votacao.id === votacaoAtiva.id);
          setVotos(votosFiltrados);

          const pautaEncontrada = pautasRes.data.find(pauta => pauta.id === votacaoAtiva.pautaId);
          setMinuta(pautaEncontrada?.minuta || "Minuta não disponível");
        } else {
          setVotos([]);
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };

    fetchDados();
    const interval = setInterval(fetchDados, 1000);
    return () => clearInterval(interval);
  }, []);

  
 // Filtra somente os itens cujo status seja "exibindo"
 const itensExibidos = painelItens.filter(item => item.status === "exibindo" || item.status === "votacao");

 // Dentro dos itens exibidos, separa pautas e vereadores:
 const pautasExibidas = itensExibidos.filter(item => 
   item.autor && item.autor.trim() !== "" && (!item.cargo || item.cargo.toLowerCase() !== "discursante")
 );
 const vereadoresExibidos = itensExibidos.filter(item => 
   item.cargo && item.cargo.toLowerCase() === "discursante"
 );

 const tempoTotalFala = 900; // 15 minutos

 const calcularTempoRestante = (vereador) => {
  if (!vereador) return tempoTotalFala;

  const tempoTotal = vereador.tempoTotal || tempoTotalFala; // Pega tempo individual se existir
  const tempoUsado = vereador.tempoUsado || 0;

  if (!vereador.dataHoraInicioFala) {
    // Se ainda não começou a falar, retorna o tempo total - usado
    const restante = tempoTotal - tempoUsado;
    return restante > 0 ? restante : 0;
  }

  const inicio = new Date(vereador.dataHoraInicioFala).getTime();
  const agora = Date.now();

  const tempoDecorrido = Math.floor((agora - inicio) / 1000);
  const totalDecorrido = tempoUsado + tempoDecorrido;
  const restante = tempoTotal - totalDecorrido;

  return restante > 0 ? restante : 0;
};

   useEffect(() => {
     // Inicia o contador para cada vereador exibido
     const interval = setInterval(() => {
       setTemposVereadores(prevTempos => {
         const novosTempos = { ...prevTempos };
 
         vereadoresExibidos.forEach(vereador => {
           if (!novosTempos[vereador.id]) {
             novosTempos[vereador.id] = 900; // 15 minutos em segundos
           } else if (novosTempos[vereador.id] > 0) {
             novosTempos[vereador.id] -= 1; // Decrementa o tempo
           }
         });
 
         return novosTempos;
       });
     }, 1000); // Atualiza a cada 1 segundo
 
     return () => clearInterval(interval); // Limpa o intervalo quando o componente desmontar
   }, [vereadoresExibidos]);

  const formatarData = (dataISO) => {
    const data = new Date(dataISO);
    return data.toLocaleDateString("pt-BR", {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  };
  
  const formatarTempo = (segundos) => {
    const s = Math.max(0, segundos); // evita tempo negativo
    const horas = String(Math.floor(s / 3600)).padStart(2, "0");
    const minutos = String(Math.floor((s % 3600) / 60)).padStart(2, "0");
    const seg = String(s % 60).padStart(2, "0");
    return `${horas}:${minutos}:${seg}`;
  };
  
  
  return (
    <div className={styles.painelSessaoContainer}>
      <div className={styles.dashboardContent}>
        <header className={styles.headerSessao}>
          <h2>{sessao ? `Sessão ${sessao.tipo} nº ${sessao.numero}` : "Nenhuma sessão ativa"} - {sessao ? formatarData(sessao.data) : "Data não disponível"}</h2>
          <div className={styles.cronometroSessao}>{formatarTempo(tempoSessao)}</div>
        </header>
  
        <main className={styles.painelPrincipal}>
          {votacaoAtiva ? (
           <section className={styles.votacaoAndamentoBox}>
           <h3>🗳️ Votação em Andamento</h3>
         
           {/* Mostrar cada matéria/pauta sendo votada */}
           {votacoesAtivas.map((votacao) => {
             const pauta = painelItens.find(item => item.pautaId === votacao.pautaId);
             return (
               <div key={votacao.id} className={styles.pautaVotada}>
                 <h4>{pauta?.titulo || votacao.descricao || `Votação #${votacao.id}`}</h4>
                 <p><strong>Minuta:</strong> {pauta?.minuta || "Minuta não disponível"}</p>
               </div>
             );
           })}
         
           {/* Lista de votos única por parlamentar */}
           {votos.length === 0 ? (
             <p>Aguardando votos...</p>
           ) : (
             <ul>
               {Object.values(
                 votos.reduce((acc, voto) => {
                   const chave = voto.usuario?.id || voto.usuarioId;
                   if (!acc[chave]) acc[chave] = voto;
                   return acc;
                 }, {})
               ).map((v) => (
                 <li key={v.id}>
                   <strong>{v.usuario?.nome || v.usuarioNome}:</strong> {v.escolha}
                 </li>
               ))}
             </ul>
           )}
         </section>
         
          ) : (
            <>
              <section className={styles.discussao}>
                <h3>Em discussão</h3>
                {pautasExibidas.length > 0 ? (
                  pautasExibidas.map(item => (
                    <div key={item.id}>
                      <strong className={styles.backgroundTitulo}>{item.titulo}</strong>
                      <p className={styles.backgroundTitulo}>Autoria: {item.autor}</p>
                      <strong className={styles.backgroundTitulo}>Minuta:</strong>
                      <p className={styles.backgroundTitulo}>{item.minuta}</p>
                    </div>
                  ))
                ) : (
                  <p style={{ color: "red" }}>Nenhuma pauta encontrada.</p>
                )}
              </section>
  
              {vereadoresExibidos.length > 0 && (
                <section className={styles.vereadorEmFala}>
                  <h3>Na tribuna</h3>
                  <div className={styles.vereadorDestaque}>
                    <img
                      src={`${process.env.REACT_APP_API}/images/vereadores/${vereadoresExibidos[0].id}_${vereadoresExibidos[0].titulo.replace(/\s+/g, '')}.png`}
                      alt={`Foto de ${vereadoresExibidos[0].titulo}`}
                      onError={(e) => (e.target.src = "/images/placeholder.png")}
                    />
                    <div className={styles.pautaGrupo}>
                      <h2>{vereadoresExibidos[0].titulo}</h2>
                      <img
                        className="partidoImg"
                        src={`${process.env.REACT_APP_API}/images/partidos/${vereadoresExibidos[0].siglaPartido}.png`}
                        alt={`Partido ${vereadoresExibidos[0].siglaPartido}`}
                        onError={(e) => (e.target.src = "/images/partido.png")}
                      />
                    </div>
                    <div className={styles.timerDisplay}>
                        <div><strong>Total:</strong> {formatarTempo(tempoTotalFala)}</div>
                        <div><strong>Restante:</strong> {formatarTempo(calcularTempoRestante(vereadoresExibidos[0]))}</div>
                        <div className={styles.progressBarWrapper}>
                          <div
                            className={styles.progressBar}
                            style={{
                              width: `${calcularPorcentagemRestante(vereadoresExibidos[0])}%`,
                              backgroundColor:
                                calcularTempoRestante(vereadoresExibidos[0]) > 300
                                  ? "#28a745" // verde
                                  : calcularTempoRestante(vereadoresExibidos[0]) > 60
                                  ? "#ffc107" // amarelo
                                  : "#dc3545", // vermelho
                            }}
                          />
                        </div>
                      </div>
                  </div>
                </section>
              )}
            </>
          )}
        </main>
      </div>
    </div>
  );
  
};

export default PainelSessao;
