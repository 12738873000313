import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./Modal.module.css";
import { FaPoll, FaTimesCircle, FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";

const ModalResultadoVotacao = ({ sessaoId, fecharModal, onClose }) => {
  const [votacoes, setVotacoes] = useState([]);
  const [presentes, setPresentes] = useState([]);
  const [totalVereadores, setTotalVereadores] = useState(0);
  const [votacoesSelecionadas, setVotacoesSelecionadas] = useState([]);
  const [resultadosFinais, setResultadosFinais] = useState([]);

  useEffect(() => {
    const carregarDados = async () => {
      try {
        const [votacoesRes, presencasRes, camaraRes] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API}/votacoes?sessaoId=${sessaoId}`),
          axios.get(`${process.env.REACT_APP_API}/presencas/sessao/${sessaoId}`),
          axios.get(`${process.env.REACT_APP_API}/camara-municipal`),
        ]);

        const votacoesAtivas = votacoesRes.data.filter((v) => v.status === "andamento");
        setVotacoes(votacoesAtivas);
        setPresentes(presencasRes.data.filter((p) => p.presente === 1));
        setTotalVereadores(camaraRes.data.totalVereadores || 0);
      } catch (error) {
        console.error("Erro ao carregar dados:", error);
        toast.error("Erro ao carregar as votações.");
      }
    };

    carregarDados();
  }, [sessaoId]);

  const toggleVotacaoSelecionada = (votacao) => {
    setResultadosFinais([]);
    setVotacoesSelecionadas((prev) => {
      const jaSelecionada = prev.find((v) => v.id === votacao.id);
      return jaSelecionada
        ? prev.filter((v) => v.id !== votacao.id)
        : [...prev, votacao];
    });
  };

  const calcularResultados = async () => {
    const resultados = [];

    for (const votacao of votacoesSelecionadas) {
      try {
        const votosRes = await axios.get(`${process.env.REACT_APP_API}/voto/votacao/${votacao.id}`);
        const votos = votosRes.data;

        const votosSim = votos.filter((v) => v.escolha === "sim").length;
        const votosNao = votos.filter((v) => v.escolha === "nao").length;
        const votosAbstencaoDiretos = votos.filter((v) => v.escolha === "abstencao").length;

        const idsPresentes = presentes.map((p) => p.vereadorId);
        const idsVotantes = votos.map((v) => v.vereadorId);
        const ausentesQueNaoVotaram = idsPresentes.filter((id) => !idsVotantes.includes(id));
        const votosAbstencao = votosAbstencaoDiretos + ausentesQueNaoVotaram.length;

        if (votos.length === 0) {
          toast.info(`Nenhum voto registrado para "${votacao.descricao}". Os presentes foram contados como abstenções.`);
        }

        let minimoAprovacao = 0;

        switch (votacao.tipoVotacao) {
          case "Maioria Absoluta":
            minimoAprovacao = Math.floor(totalVereadores / 2) + 1;
            break;
          case "Maioria Qualificada":
            minimoAprovacao = Math.ceil(totalVereadores * (2 / 3));
            break;
          case "Maioria Simples":
          default:
            minimoAprovacao = Math.floor(presentes.length / 2) + 1;
            break;
        }

        const aprovado = votosSim >= minimoAprovacao;

        await axios.patch(`${process.env.REACT_APP_API}/votacoes/encerrar/${votacao.id}`, {
          aprovado: aprovado ? "Aprovado" : "Rejeitado",
        });

        if (votacao.pautaId) {
          await axios.post(`${process.env.REACT_APP_API}/pautas/${votacao.pautaId}/alterarStatus`, {
            status: aprovado ? "Aprovado" : "Rejeitado",
          });
        }

        resultados.push({
          id: votacao.id,
          descricao: votacao.descricao,
          tipoVotacao: votacao.tipoVotacao,
          votosSim,
          votosNao,
          votosAbstencao,
          minimoAprovacao,
          aprovado,
          nenhumVoto: votos.length === 0,
        });
      } catch (error) {
        console.error("Erro ao processar votação:", votacao.id, error);
        toast.error(`Erro ao processar votação ID ${votacao.id}`);
      }
    }

    setResultadosFinais(resultados);
    toast.success("Resultados gerados com sucesso!");
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
                <button className={styles.modalClose} onClick={onClose}>
                  <FaTimes />
                </button>
        <h2><FaPoll /> Resultado da Votação</h2>

        {votacoes.length === 0 ? (
          <p>Não há votações em andamento nesta sessão.</p>
        ) : resultadosFinais.length === 0 ? (
          <>
            <p>Selecione uma ou mais votações para gerar o resultado:</p>
            <ul className={styles.listaVotacoes}>
              {votacoes.map((v) => (
                <li key={v.id} className={styles.itemVotacao}>
                  <label>
                    <input
                      type="checkbox"
                      checked={votacoesSelecionadas.some((sel) => sel.id === v.id)}
                      onChange={() => toggleVotacaoSelecionada(v)}
                    />
                    🗳️ {v.descricao || `Votação ID ${v.id}`} - {v.tipoVotacao}
                  </label>
                </li>
              ))}
            </ul>

            {votacoesSelecionadas.length > 0 && (
              <div>
                <p><strong>Vereadores Presentes:</strong> {presentes.length} / <strong>Total na Câmara:</strong> {totalVereadores}</p>
                <button onClick={calcularResultados} className={styles.btnAvancar}>
                  Gerar Resultados
                </button>
              </div>
            )}
          </>
        ) : (
          <>
            {resultadosFinais.map((res, index) => (
              <div key={index} className={styles.resultadoContainer}>
                <p><strong>Votação:</strong> {res.descricao}</p>
                <p><strong>Tipo:</strong> {res.tipoVotacao}</p>
                {res.nenhumVoto && (
                  <p className={styles.alerta}>
                    ⚠ Nenhum voto foi registrado. Todos os presentes foram considerados como abstenção.
                  </p>
                )}
                <p><strong>Sim:</strong> {res.votosSim}</p>
                <p><strong>Não:</strong> {res.votosNao}</p>
                <p><strong>Abstenção:</strong> {res.votosAbstencao}</p>
                <p><strong>Mínimo para aprovação:</strong> {res.minimoAprovacao}</p>
                <h3 className={res.aprovado ? styles.aprovado : styles.rejeitado}>
                  {res.aprovado ? "Aprovado" : "Rejeitado"}
                </h3>
              </div>
            ))}
          </>
        )}

        <div className={styles.modalActions}>
          <button onClick={fecharModal} className={styles.btnCancelar}>
            <FaTimesCircle /> Fechar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalResultadoVotacao;
